import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {IAvatarModel} from "../../../../../models/avatar/IAvatarModel";
import * as s from "./avatar.scss";
import {AvatarTemplate} from "./types/AvatarTemplate";

export class AvatarSimpleComponent extends Component {

    public avatar: IAvatarModel;

    constructor(avatar: IAvatarModel) {
        super();
        this.avatar = avatar;


        //language=HTML
        this.template = `
            <div class="${s.componentAvatar}"></div>
        `;
    }

    commit() {
        super.commit();
        this.onReactiveObserve(() => this.init());
    }

    init() {
        this.removeAttribute("style");
        if (this.avatar) {
            if (this.avatar && this.avatar.color != undefined) {
                this.setStyle(`background-image:${this.avatar.color.color};background:${this.avatar.color.color}`);
            }

            let template = AvatarTemplate.imageTemplate(this.avatar);
            this.setHtml(template);

            let avatarContainer = this.el(s.avatar);
            if (!avatarContainer.isNull()) {
                avatarContainer.addListener("error", () => {
                    let template = AvatarTemplate.iconDivTemplate(this.avatar);
                    this.setHtml(template);
                });
            }
        }
    }
}