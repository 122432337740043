import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-articles.scss";
import {BlogArticleComponent} from "./article/BlogArticleComponent";
import {ICommunityArticlesModel} from "../../../../../../../models/community/article/ICommunityArticlesModel";
import {ICommunityArticleModel} from "../../../../../../../models/community/article/ICommunityArticleModel";
import {
    PagingComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/paging/PagingComponent";
import {
    LoaderObjectBlogComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/objects/blog/LoaderObjectBlogComponent";
import {CommunityArticleService} from "../../../../../../../services/community/articles/CommunityArticleService";
import {CommunityService} from "../../../../../../../services/community/CommunityService";
import {Resources} from "../../../../../../../resources/Resources";
import {
    EmptyBasicComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {ICommunityCategoryModel} from "../../../../../../../models/community/category/ICommunityCategoryModel";
import {
    SedestralInterface
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {ICommunityLiveModel} from "../../../../../../../models/community/ICommunityLiveModel";
import {
    HexFilterConverter
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/colors/types/HexFilterConverter";
import {
    filterXSS
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class BlogArticlesComponent extends Component {

    public live: ICommunityLiveModel;
    public articles: ICommunityArticlesModel;

    public articlesContainer: Component;
    public pagingComponent: PagingComponent;

    public category: ICommunityCategoryModel;

    constructor(live: ICommunityLiveModel) {
        super();
        this.live = live;
        //language=HTML
        this.template = `
            <div class="${s.componentBlogArticles}"></div>`;
    }

    commit() {
        this.pagingComponent = new PagingComponent(false);
        super.commit();
    }

    async renderAll(category?: ICommunityCategoryModel) {
        this.category = category;

        this.pagingComponent = new PagingComponent(false);

        let loaderTimeout = this.timeOut(() => this.renderLoader(), 100);

        this.articles = await CommunityArticleService.findAll(this.live.community.id, this.pagingComponent.getOffsetLimit(), this.pagingComponent.getLimit(), category ? category.route : undefined);

        clearTimeout(loaderTimeout);
        this.removeLoader();

        if (category) {
            //language=HTML
            let categoryComponent = this.append(`
                <div itemscope itemtype="https://schema.org/CollectionPage" class="${s.category}">
                    <div itemprop="name" class="${s.name}">${filterXSS(category.title)}</div>
                    <div itemprop="description" class="${s.description}">${filterXSS(category.description)}</div>
                </div>
            `);

            if (category.imageFile) {
                let categoryIcon = categoryComponent.prepend(`<img itemprop="image" alt="${category.title}" src="${category.imageFile.link}"/>`);
                this.onReactiveObserve(() => categoryIcon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].titleColor)));
            }

            let description = categoryComponent.el(s.description);
            let name = categoryComponent.el(s.name);
            this.onReactiveObserve(() => {
                name.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].titleColor};`);
                description.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].titleColor};`);
            });
        }

        if (this.articles && this.articles.articles.length > 0) {
            if (this.category)
                this.articles.articles.sort((a, b) => a.indexOrder > b.indexOrder ? 1 : -1);
            else
                this.articles.articles.sort((a, b) => a.time < b.time ? 1 : -1);


            this.articlesContainer = this.append(`<div class="${s.articles}"></div>`);
            this.articles.articles.forEach((article) => this.renderArticle(article));

            this.renderPaging();
        } else {
            this.renderEmpty();
        }

        SedestralInterface.main.scrollTop(0, 250);
    }

    renderPaging() {
        //language=HTML
        this.append(`
            <div class="${s.paging}">
                ${this.draw(this.pagingComponent)}
            </div>
        `);

        this.pagingComponent.renderPages(this.articles.totalArticlesCount);
        this.onReactiveObserve(() => this.pagingComponent.getPagesRounds().forEach(value => value.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};`)));
    }

    renderArticle(article: ICommunityArticleModel) {
        let category = this.live.categories.find(value => value.id == article.categoryId);

        //language=HTML
        this.articlesContainer.append(`
            <a href="${CommunityService.linkArticle(category, article)}" class="${s.article}">
                ${this.articlesContainer.draw(new BlogArticleComponent(this.live, article, this.live.categories.find(value => value.id == article.categoryId), this.articles.writers))}
            </a>
        `);
    }

    renderEmpty() {
        this.addClass(s.full);
        let emptyComponent = new EmptyBasicComponent("🧐", Resources.t("words.noNews"));

        //language=HTML
        this.append(`
            <div class="${s.loader}">
                ${this.draw(emptyComponent)}
            </div>
        `);

        this.onReactiveObserve(() => {
            emptyComponent.textContainer.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].mainColor};`);
        });
    }

    renderLoader() {
        this.clearAll();
        this.addClass(s.full);
        this.render(new LoaderObjectBlogComponent());
    }

    removeLoader() {
        this.removeClass(s.full);
        this.clearAll();
    }
}