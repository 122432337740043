import {
    Component
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-article-owner.scss";
import {IAccountModel} from "../../../../../../../../../models/account/IAccountModel";
import {ICommunityArticleModel} from "../../../../../../../../../models/community/article/ICommunityArticleModel";
import {Resources} from "../../../../../../../../../resources/Resources";
//import {AvatarComponent} from "../../../../../../../../panel/components/components/avatar/AvatarComponent";
import {ICommunityLiveModel} from "../../../../../../../../../models/community/ICommunityLiveModel";
import {
    filterXSS
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {AvatarSimpleComponent} from "../../../../../../../../panel/components/components/avatar/AvatarSimpleComponent";
import {ProductType} from "../../../../../../../../../models/product/ProductType";
import {
    join
} from "../../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/String";

export class BlogArticleOwnerComponent extends Component {

    public live: ICommunityLiveModel;

    public writers: IAccountModel[];
    public article: ICommunityArticleModel;

    public names: Component;
    public work: Component;

    constructor(live: ICommunityLiveModel, article: ICommunityArticleModel, writers: IAccountModel[]) {
        super();
        this.live = live;
        this.writers = writers;
        this.article = article;

        //language=HTML
        this.template = `
            <div class="${s.componentBlogArticleOwner}">
                <div itemprop="image" class="${s.avatarContainer}">
                    ${writers.length > 0 ? this.draw(new AvatarSimpleComponent(writers[0].avatar)) : ``}
                </div>
                <div itemprop="author" class="${s.names}">
                    <div itemprop="name" class="${s.creatorName}">
                        ${join(writers.map(value => filterXSS(value.name)))}
                    </div>
                    <div class="${s.work}">
                        ${join(writers.map(value => filterXSS(value.work)))}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.names = this.el(s.names);
        this.work = this.el(s.work);

        this.onReactiveObserve(() => {
            this.names.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondColor};`);
            this.work.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondTextColor};`);
        });

        super.commit();
    }

}