import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./blog-read.scss";
import {BlogArticleOwnerComponent} from "../articles/article/owner/BlogArticleOwnerComponent";
import {
    LoaderObjectArticleComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/objects/article/LoaderObjectArticleComponent";
import {CommunityArticleService} from "../../../../../../../services/community/articles/CommunityArticleService";
import {ICommunityLiveModel} from "../../../../../../../models/community/ICommunityLiveModel";
import {Network} from "../../../../../../../network/Network";
import {ShareComponent} from "../../../../../../panel/components/components/share/ShareComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {ICommunityArticleViewModel} from "../../../../../../../models/community/article/ICommunityArticleViewModel";
import {config} from "../../../../../../../config";
import {
    HexFilterConverter
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/colors/types/HexFilterConverter";
import {
    dateFormatMeta
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatMeta";
import {
    filterXSS
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";
import {
    dateFormatArticle
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormatArticle";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class BlogReadComponent extends Component {

    public live: ICommunityLiveModel;

    constructor(live: ICommunityLiveModel) {
        super();
        this.live = live;
        //language=HTML
        this.template = `
            <div class="${s.componentBlogRead}">
                <div class="${s.loader}">
                    ${this.draw(new LoaderObjectArticleComponent(true))}
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();
        this.renderAll()
    }

    async renderAll() {
        let view = await CommunityArticleService.view(this.live.community.id, Network.router.pages.getParamAtIndex(2), this);
        let share = new ShareComponent(view.article.title, document.URL);

        this.onReady(view);
        this.clearAll();
        //language=HTML
        this.append(`
            <article itemscope itemtype="https://schema.org/BlogPosting">
                <header>
                    <h1 class="${s.title}" itemprop="headline">${filterXSS(view.article.title)}</h1>
                    <p class="${s.time}">
                        ${Resources.t("words.publishedDate")}
                        <time itemprop="datePublished" datetime="${dateFormatMeta(new Date(view.article.time))}">
                            ${dateFormatArticle(new Date(view.article.time))}
                        </time>
                        ${Resources.t("words.publishedIn")}
                        <meta itemprop="inLanguage" content="${view.category.language}">
                        <a itemprop="articleSection" href="${config.domain + view.category.route}">${filterXSS(view.category.title)}</a>
                    </p>
                    ${view.article.writersAccounts.length > 0 ? this.draw(new BlogArticleOwnerComponent(this.live, view.article, view.article.writersAccounts)) : ''}
                </header>
                <link itemprop="image" href="${view.article.imageFile?.link}">
                <div class="${s.content}" itemprop="articleBody">
                    ${view.article.html}
                </div>
                <footer>
                    ${view.article.lastEditTime > 0 ? `<p class="${s.update}">
                        ${Resources.t("words.lastEdit")}
                        <time itemprop="dateModified" datetime="${dateFormatMeta(new Date(view.article.lastEditTime))}">${dateFormatArticle(new Date(view.article.lastEditTime))}</time>
                    </p>` : ``}
                </footer>
                ${view.article.keywords.length > 0 ? `<meta itemprop="keywords" content="${filterXSS(view.article.keywords.join(", "))}">` : ``}
                <div class="${s.share}">
                    ${this.draw(share)}
                </div>
                <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
                    <meta itemprop="name" content="${this.live.community.name}">
                    ${this.live.community.logoFile ? `<link itemprop="logo" href="${this.live.community.logoFile.link}">` : ``}
                </div>
            </article>
        `);

        let title = this.el(s.title);
        let content = this.el(s.content);
        let time = this.el(s.time);
        let update = this.el(s.update);

        this.onReactiveObserve(() => {
            title.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].titleColor};`);
            content.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].textColor};`);
            time.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondTextColor};`);

            share.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].textColor};`);
            share.networkIcons.forEach(icon => {
                icon.setStyle(HexFilterConverter.convert(this.live.community.appearances[Resources.theme(ProductType.BLOG)].textColor));
            });

            if (!update.isNull()) {
                update.setStyle(`color:${this.live.community.appearances[Resources.theme(ProductType.BLOG)].secondTextColor};`);
            }
        });
    }

    onReady(view: ICommunityArticleViewModel) {

    }

}