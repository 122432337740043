@use "../../../../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentBlogArticleOwner {
  display: flex;
  align-items: center;

  .avatarContainer {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 50%;
    cursor: pointer;
  }

  .names {
    .creatorName {
      font-size: 14px;
      cursor: pointer;
    }

    .work {
      color: var(--grey-text-dark-blog);
      font-size: 13px;
    }
  }
}